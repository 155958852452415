import { type ReactNode } from 'react'
import { type JsonNode, RenderJson } from './RenderJson'

interface ContentstackJsonRteChildrenProps {
  /**
   * Array of JsonNode from the json object from contentstack's rich text editor
   */
  children: JsonNode[]
  /**
   * Exclude outer tags and only return children
   */
  excludeOuterTags?: boolean
  tableStyle?: string[] | null
}

/**
 * Maps the children array that is part of the JSON object from contentstack's rich text editor
 */
function ContentstackJsonRteChildren ({ children, excludeOuterTags, tableStyle }: ContentstackJsonRteChildrenProps): ReactNode {
  return children.map((data, i) => <RenderJson key={data?.uid ?? i} excludeOuterTags={excludeOuterTags} tableStyle={tableStyle} {...data} />)
}

export { ContentstackJsonRteChildren }
